@import '../../base.scss';


.languages{
    z-index: 3;
    margin-right: 5px;
    // width: 138px;
    align-self: flex-end;
    display: block;
    margin-right: -10px;
    text-align: end;
    a{
        // font-size: 1rem;
        font-size: 1rem;
        // color: $brandColor;
        color: #000;
        text-decoration: none;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        background-color: transparent;
        margin-top: 3px;
        text-transform: uppercase;
        display: inline-block;
        width: 20px;
        margin-right: 10px;
        &:hover{
            color: grey;
            -webkit-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
        }
    }
    a:not(:last-child){
        // margin-right: 10px;
    }
}


@media (max-width: 550px) {
    .languages {
        // margin-right: 0px;
        padding-bottom: 6px;
    }    
}


@media (max-width: 400px){
        .languages {
            padding-bottom: 4px;
        a {
            // font-size: 1.9rem;
        }
    }
}


@media (max-width: 375px){
        .languages {
            // padding-bottom: 4px;
        a {
            // font-size: 1.8rem;
        }
    }
}


@media (max-width: 350px){
        .languages {
            padding-bottom: 0px;
        a {
            // font-size: 1.6rem;
        }
    }
}


@media (max-width: 325px){
        .languages {
            // width: 118px;
        a {
            // font-size: 1.4rem;
        }
    }
}


@media (max-width: 300px){
        .languages {
        a {
            // font-size: 1.3rem;
        }
    }
}

