@import '../../base.scss';


.aside{
    position: absolute;
    right: 40px;
    top: 0px;
    // width: 20%;
    height: 100%;
    z-index: 2; 
    overflow: hidden;
    max-width: 19%;
    width: 32vh;
    // background-color: burlywood;
    // max-height: 32%;
    max-height: 100%;
    order: 2;
    &__wrap{       
        height: 100%; 
        position: -webkit-sticky; 
        position: sticky;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;

      }
    }
    &__list{
        right: 0px;
        top: 0px;
        z-index: -1;
        // display: -webkit-box;
        display: flex;
        
        flex-direction: column;
        justify-content: space-between;
        // justify-content: flex-start;
        align-items: flex-end;//07.12
        height: 100%;
        padding-left: 15px;

        li:not(:last-child){
            // margin-right: 5px;
            margin-bottom: 2px;
        }  

        // li{
        //     width: 100%;
        //     // height: 100%;
        //     // background-color: $brandColor;
        //     margin-right: 0px;
        //     margin-left: 0px;
        //     position: relative;
        //     padding: 0px 0px 0px 0px;
        //     -webkit-transition: all 0.2s ease;
        //     -o-transition: all 0.2s ease;
        //     transition: all 0.2s ease;
        //     aspect-ratio: 16 / 9;
        //     max-height: 15.9vh;
        //     // max-height: 25%;
        //     flex: 0 1 auto;
            
        //     object{
        //         width: 100%;
        //         height: 100%;
        //         // margin-bottom: 10px;
        //         object-position: center;
        //         object-fit: cover;
        //         -o-object-fit: cover;
        //         position: absolute;
        //         left: 0;
        //         top: 0;  
        //         // top: 50%;
        //         // left: 50%;
        //         // transform: translate(-50%, -50%);
        //         // text-align: center;
                
        //     }
        //     // iframe {
        //     //     // position: absolute;
        //     //     // top: 0;
        //     //     // left: 0;
        //     //     width: 100%;
        //     //     height: 100%;
        //     //     -o-object-fit: cover;
        //     //        object-fit: cover;

        //     //        position: absolute;
        //     //        top: 50%;
        //     //        left: 50%;
        //     //        transform: translate(-50%, -50%);
        //     //        text-align: center;
        //     // }
        // }
    }

    &__cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(251, 251, 251, 0.2);
        cursor: pointer;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        transition: all ease 0.3s;
        &:hover {
            background-color: transparent;
            transition: all ease 0.3s;
        }
    }
}

@media (max-width: 1024px) {

    .aside{
        position: relative;   
        right: 0px;     
        width: 100%;
        height: 100%;
        z-index: 2;
        // overflow: auto;
        overflow-y: hidden;
        margin-top: 50px;
        max-width: 100%;
        // width: 33vh;
        margin: 50px 0px 0px 0px;
        &__wrap{
            // margin: 40px 0px 140px 0px;
            margin: 40px 0px 190px 0px;
            max-height: 450px;
            position: relative;
            overflow-y: hidden; 
            overflow-x: auto;
        }
        &__list{

            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            z-index: 1;

            white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            padding: 0; 

            flex-direction: row;
            align-items: center;//07.12
            justify-content: space-between;//2videos
    
            li{
                display: inline-block;
                // width: 720px;
                width: 620px;
                // width: 100%;
                margin: 0px;
                // padding: 0px 0px 6% 0px;
                margin-bottom: 0px;
                margin-right: 0px;
                margin-left: 0px;
                -webkit-overflow-scrolling: touch;
                text-align: center;
                max-height: unset;
                overflow: hidden;
                // object{
                //     width: 105%;
                //     // text-align: center;
                //     // height: 0;
                //     // max-height: 100%;
                //     // max-width: 100%;
                //     // min-height: 100%;
                //     // min-width: 100%;

                // }
            }
            li:not(:last-child){
                margin-right: 7px;
                margin-bottom: 0px;
            }    
        }    
    }   
}


@media (max-width: 768px) {
    .aside{
        margin-top: 30px;
        &__wrap{
            margin: 40px 0px 120px 0px;                
            height: 100%;
        }
        &__list{    
            li{   
                // width: 500px;
                width: 400px;
                // width: 100%;
                margin-bottom: 0px;
                // padding: 0px 0px 6% 0px;
                // iframe{
                //     // width: 500px;
                // }
            }
            li:not(:last-child){
                // margin-right: 5px;
                margin-bottom: 0px;
            }    
        } 
    }
}


@media (max-width: 600px) {
    .aside{
        margin-bottom: 60px;
        margin-top: 20px;
        &__wrap{
            margin: 40px 0px 60px 0px;
        }
        &__list{    
            li{   
                // width: 400px;
                width: 300px;
            }    
        } 
    }
}


@media (max-width: 425px) {
    .aside{
        margin-top: 0px;
        &__wrap{
            margin: 40px 0px 50px 0px;
        }
        &__list{    
            li{   
                // width: 300px;
                width: 210px;
                // padding: 0px 0px 7% 0px;
                object{
                    // width: 300px;
                }
            }
            li:not(:last-child){
                // margin-right: 33px;
                margin-bottom: 0px;
            }    
        }
    }
}


@media (max-width: 350px) {
    .aside{
        &__wrap{
            margin: 40px 0px 45px 0px;
        }
        &__list{    
            li{   
                // width: 250px;
                width: 180px;
                // iframe{
                //     // width: 250px;
                // }
            }    
        }   
    }
}

