@import '../../base.scss';

.btn {
    font-size: 2.05rem;
    color: $brandColor;
    z-index: 20;
    margin-top: 3px; 
    text-transform: uppercase;
    min-width: 137px;
    text-align: end;
    line-height: 100%;
    // transition: all ease 0.4s;
    -webkit-tap-highlight-color: transparent;
    // transform: scale(1);
    // &:hover{
    //     // transform: scale(1.05);
    //     transition: all ease 0.4s;
    //     filter: grayscale(0.5);
    // }
}
.btn._active {
    color: white;
    z-index: 26;
    text-transform: uppercase;
    -webkit-tap-highlight-color: transparent;
    // top: -70px;
	// padding-bottom: 70px;
	// transform: translateY(70px);
}


@media (max-width: 480px) {
    .btn {
        text-align: end;
        margin-left: 10px;
        vertical-align: bottom;
        // margin-bottom: -9px;
    }
}

@media (max-width: 400px) {
    .btn {
        font-size: 1.9rem;
        margin-bottom: 5px;
        // padding-bottom: 5px;        
    }
}

@media (max-width: 375px){
    .btn{
        min-width: 117px;
        font-size: 1.8rem;   
    }
}

@media (max-width: 350px) {
    .btn {
        font-size: 1.6rem;
        // margin-bottom: -4px;
    }
}

@media (max-width: 325px) {
    .btn {
        font-size: 1.4rem;
        min-width: 95px;
        // margin-bottom: 0px;
    }
}

@media (max-width: 300px) {
    .btn {
        font-size: 1.3rem;
        min-width: 90px;
    }
}

