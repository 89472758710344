$brandColor: #FA3B5A;


@font-face {
  font-family: "Activist-plus";
  src: url('./assets/font/Activist-plus.eot');
  src: url('./assets/font/Activist-plus.woff') format('woff'),
  url('./assets/font/Activist-plus.woff2') format('woff2'),
  url('./assets/font/Activist-plus.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Activist";
  src: url('./assets/font/Activist.eot');
  src: url('./assets/font/Activist.woff') format('woff'),
  url('./assets/font/Activist.woff2') format('woff2'),
  url('./assets/font/Activist.ttf') format('truetype');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}


// небольшое обнуление---------------
*{
  padding: 0;
  margin: 0;
  border: 0;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}

nav,footer,header,aside{display: block;}
input,button,textarea{font-family: inherit;}
*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6{
  font-size: inherit;
  font-weight: 400;
}
button{cursor: pointer; background-color: inherit;}
button::-moz-focus-inner{padding: 0; border: 0;} // убирает лишние отступы в firefox для кнопок
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}

//-----------------------------------------------------