@import '../../base.scss';


.header__padding{
    padding: 0px 0px 20px 0px;
}
.header {
    width: 100%;
    padding: 2rem 0;

    &__wrap{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin: 0px 40px;
    }
    
    &__description{
        margin-top: 10px;
        width: 216px;
        height: 54px;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        max-width: 216px;
        img{
            width: 100%;
        }
    }

    &__broadcast{
        // line-height: 90%;
        text-align: center;
        color: black;
        font-size: 2.05rem;
        margin-top: 2px;
        margin-left: 5px;
        margin-right: 5px;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        span{
            color: $brandColor;
        }
    }
    
    &__nav{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        height: 2rem;
        margin-top: 4px;
    }
}

@media (min-width: 1025px){
    .header__description{
        width: 236px;
        max-width: 236px;
    }
}


@media (max-width: 1250px){
    .header__broadcast{
        max-width: 400px;
    }
}


@media (max-width: 1024px) {
    .header__broadcast{
        max-width: 100%;
        width: 100%;
        top: 160px;
        // line-height: 100%;
        line-height: 90%;
        margin: 0px;
    }
    .header__title{
        position: relative;
        margin: 0 auto;
    }
    .header__wrap{
        margin: 20px 40px 50px 40px;
    } 
}


@media (max-width: 768px) {
    .header__wrap{
        margin: 20px 15px 50px 15px;
    }   
    .header{
        padding: 2rem 2%;
        width: 100%;
        &__broadcast{
            padding: 0 2%;
        }
    }  
}


@media (max-width: 625px) {
    .header__wrap{
        margin: 20px 15px 60px 15px;
    } 
    .header__broadcast{
        // text-align: unset;
        padding: 0 30px;        
    }
}


@media (max-width: 550px) {

    .header__wrap{
        align-items: flex-end; 
    }
    .header__description{
        margin-bottom: 4px;
    }
    .header__nav{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        margin-top: 10px;
        text-align: end;
    }    
}


@media (max-width: 480px) {
    .header{
        margin-top: 0px;
    } 
    .header__nav{
        margin-top: 0px;
    }
    .header__broadcast{
        font-size: 1.95rem;
        top: 160px;
        text-align: unset;
    }
   
}

@media (max-width: 400px){
    .header__wrap{
        margin: 10px 15px 50px 15px;
    } 
    .header__broadcast{
        font-size: 1.8rem;
        top: 150px;
    }
    .header__description{
        width: 190px;
        img{
            width: 190px; 
        } 
    }
}


@media (max-width: 375px){
    .header__broadcast{
        font-size: 1.65rem;
        top: 160px;
    }
    .header__description{
        width: 180px;
        // height: 50px;
        height: 100%;
        img{
            width: 180px;
        } 
    }
}


@media (max-width: 350px) {
    .header__wrap{
        margin: 0px 15px 50px 15px;
    }
    .header__broadcast{
        font-size: 1.51rem;
        top: 140px;
    }
    .header__description{
        width: 160px;
        img{
            width: 160px;
        }        
    }  
}


@media (max-width: 325px) {
    .header__wrap{
        margin: 0px 15px 30px 15px;
    }
    .header__broadcast{
        font-size: 1.4rem;
        top: 130px;
    }
    .header__description{
        width: 140px;
        img{
            width: 140px;
        }        
    }  
}


@media (max-width: 300px) {
    .header__broadcast{
        font-size: 1.25rem;
    }
    .header__description{
        width: 130px;
        img{
            width: 130px;
        }        
    }  
}
