@import '../../base.scss';
//autoprefixed--------------------------------------------------------------

.nav__wraper{
    left: 0;
    top: 0;
    width: 100%;
    position: fixed;
    height: 100%;
    margin-left: auto;
    margin-right: 0px;
    z-index: 0;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-animation: z 0.1s ease 0.5s 1;
            animation: z 0.1s ease 0.5s 1;
    -webkit-transition: all ease 0.7s;
    -o-transition: all ease 0.7s;
    transition: all ease 0.7s;    
    
}

.nav__wraper.active{
    z-index: 25;
    -webkit-transition: all ease 0.7s;
    -o-transition: all ease 0.7s;
    transition: all ease 0.7s;
    opacity: 1;
    min-height: 100vh;
}

@-webkit-keyframes z {
    from{z-index: 9;
        opacity: 1;
    }
    to{
        opacity: 0;
        z-index: 0;
    }    
}

@keyframes z {
    from{z-index: 9;
        opacity: 1;
    }
    to{
        opacity: 0;
        z-index: 0;
    }    
}

.inner__wrap{
    max-width: 1600px;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    height: 100%;
}

.menu{
    height: 100%;

    position: absolute;
    margin: 0 auto;
    right: -200%;
    -webkit-transition: all ease 0.7s;
    -o-transition: all ease 0.7s;
    transition: all ease 0.7s;
    top: 0px;   
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 1600px;
    width: 100%;
    &__wrap{
        padding-top: 160px;        
        min-height: 100%;
        position: absolute; 
        top: 0;
        left: 0;
        width: 40vw;
        background-color: rgba(0, 0, 0, 0.8);
    }
    &__list{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        height: 100%;
        li{
            max-width: 250px;
        }
    }
}

.menu.active {
    right: -77%;
    -webkit-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
    transition: all ease 0.4s;
    z-index: 9;
}


.links{
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    text-align: left;
    margin-right: auto;
    margin-left: 10%;

    a{
        font-size: 2.05rem;
        color: $brandColor;
        margin-bottom: 30px;
        padding: 10px;
        cursor: pointer;
        display: inline-block;
        text-transform: uppercase;

        transition: all ease 0.4s;
        transform: scale(1);
        &:hover{
            transform: scale(1.1);
            transition: all ease 0.4s;
        }
    }
}
.socials{
    margin-right: auto;
    margin-left: 10%;
    text-align: left;
    img{
        width: 45px;
    }
    svg{
        width: 45px;
        transition: all ease 0.4s;
        transform: scale(1);
        border-radius: 50%;
        &:hover{
            box-shadow: 0 0 3px #ddbbd5;
            transform: scale(1.1);
            transition: all ease 0.4s;
            border-radius: 50%;
        }
    }
    a:not(:last-child){
        margin-right: 20px;
    }
    a{
        font-size: 10rem;
        color: $brandColor;
        text-transform: uppercase;
    }
}

@media (max-width: 1400px){
    .socials{ margin-left: 5%;}
    .links{ margin-left: 5%;}
}

@media (max-width: 1200px){
    .socials{ margin-left: 1%;}
    .links{ margin-left: 1%;}
}

@media (max-width: 1024px){
    .menu__list{
        li{
            max-width: 100%;
            font-size: 36px;
            text-align: center;
        }
    }
    .socials{ 
        margin-left: auto;        
    }
    .links{ 
        margin-left: auto;       
    }
    .menu__wrap{
        width: 100%;
        padding-top: 225px;
    }
    .menu.active{
        right: 0%;

    }

}

@media (max-width: 800px) {
        .socials{ 
            a{
                margin-bottom: 20px;
            }        
        }

        .links{  
            a{
                margin-bottom: 3px;
            }     
        }
}


@media (max-width: 768px) {
    .menu__wrap{
        padding-top: 180px;
    }

    .menu__list{
        li{
            font-size: 33px;
        }
    }
}

@media (max-width: 480px) {
    .menu__wrap {
        padding-top: 145px;
    }
    .menu__list{
        li{
            font-size: 28px;
        }
    }
    
}

