@import '../../base.scss';

.video.hidden{
    display: none;
}

.video {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    // background-color: rgba(97, 102, 102, 0.7);
    background-color: #ffffff00;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 10;

    -webkit-transition: all ease 0.2s;

    -o-transition: all ease 0.2s;

    transition: all ease 0.2s;
    // animation: blackScreen 5s linear forwards;
    // background-color: rgba(0, 0, 0, 1);
    // opacity: 1;
    

    &__container{
        width: 66%;
        position: relative;

        padding: 0px 0px 37% 0px;
        z-index: -1;

        iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            -webkit-animation: scaling 0.5s ease  1 forwards;

                    animation: scaling 0.5s ease  1 forwards;
            -webkit-transition: all ease 0.2s;
            -o-transition: all ease 0.2s;
            transition: all ease 0.2s;

        }
    }
    &__close{
        position: absolute;
        color:#000;
        right: -20px; 
        top: -50px;
        font-size: 50px;
        z-index: 4;
        cursor: pointer;
        transform: scale(1);
        transition: all ease 0.2s;
        &:hover{
            transform: scale(1.1);
            transition: all ease 0.2s;
        }
    }

}

.black__screen{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #000;
    z-index: 25;
    opacity: 0;
    transition: all ease 0.3s; 
    animation: blackScreen2 0.5s  ease forwards;
}

@keyframes blackScreen2{
    0%{opacity: 0;

    }
    25%{opacity: 1;}
    100%{opacity: 0;
        z-index: -1;
    }
}


@-webkit-keyframes blackScreen {
    0%{ 
        background-color: rgba(0, 0, 0, 1); 
    // opacity: 1;
// transition: all linear 0.2s;
}
    // 25%{ 
    //     // background-color: rgba(0, 0, 0, 0.5); 
    //     backdrop-filter: blur(7px);
    //     -webkit-backdrop-filter: blur(7px);}
    100%{
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        // opacity: 0;
        // transition: all linear 0.2s;
    }
}

@keyframes blackScreen {
    0%{ background-color: rgba(0, 0, 0, 1); }
    // 50%{ background-color: rgba(0, 0, 0, 1); }
    100%{
        background-color: #ffffff00;
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
    }
}


@-webkit-keyframes scaling {
    0%{

        opacity: 0;
    }
    100% {

        opacity: 1;
    }
    
}


@keyframes scaling {
    0%{

        opacity: 0;
    }
    100% {

        opacity: 1;
    }
    
}

@media (max-width: 1024px) {
    .video__container{
        width: 92%;
        padding: 0px 0px 53% 0px;
    }
    
}
@media (max-width: 500px) {
    .video__close{
        position: absolute;
        right: -5px;
        top: -50px;
        font-size: 50px;
        z-index: 4;
        cursor: pointer;
    }
    
}
