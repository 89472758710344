@import './base';

//autoprefixed--------------------------------------------------------------

.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
li.iframe-container{
  padding-bottom: 55%;  
}  

@media (max-width: 1024px) {
  li.iframe-container{
    // padding-bottom: 27%;
    padding-bottom: 16%;
  }  
}

.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

a[aria-current].active{
  display: none;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Activist-plus', 'Activist','-apple-system', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.3;
  // overflow: hidden;
  // -webkit-text-size-adjust: none;
  overflow: auto;

  &::after{
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    // transform: translate(-50%, -50%);

    background-image: url('./assets/img/bg.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left top;
    z-index: -1;
    overflow: visible;
  }
}

html{
  scroll-behavior: smooth;
}

.zagl{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: auto;
}

.zagl__wrap{
  position: fixed;
  left: 0;
  top: -5%;
  overflow: hidden;
  max-height: 100vh;
  width: 100%;
  height: 100vh;
   
      div{
        position: fixed;
        right: 0px;
        top: 0;
        padding: 44px 20px;
        a{
          margin-right: 10px;  
          width: 20px;
        }
        a[aria-current].active{              
          opacity: 0;
        }
    }


    h3{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 17vw);
      font-size: 2vw;
      color: $brandColor;
      width: 100%;
      line-height: 95%;
      text-align: center;
      a{
        text-decoration: underline;
        cursor: pointer;
        color: $brandColor;
        transition: all ease 0.3s;
        &:hover{
          text-decoration: none;            
          transition: all ease 0.3s;

        }
      }        
    }


    .h_wrapUa{
      width: 200%;
    }
    .h_wrapRu{
      width: 200%;
    }
    .logo_zagl {
      position: fixed;
      // width: 216px;
      width: 14vw;
      min-width: 170px;
      max-width: 216px;
      height: 64px;
      left: 40px;
      top: 40px;     

    }
}


.zagl_head{
  width: 100%;
  height: 120px;
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
}



#root{
  position: relative;
  
  // overflow: scroll;
  overflow: auto;
  overflow-x: hidden;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  height: 100%;
  height: -webkit-fill-available;
  margin: 0 auto;
  -webkit-overflow-scrolling: touch;
  min-height: 100vh;


}

header{
  max-width: 1600px;
  margin: 0 auto;
  // position: relative;
}


main{
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
  max-width: 1600px; 
  width: 100%;
  margin: 20px auto;  
  padding: 0;
  height: 100%;

}

#blur{
  -webkit-filter: blur(5px);
          filter: blur(5px);
}

.main__wrap{
  width: 100%;
  // max-width: 300px;
  // height: 100%;
  // height: 75vh;
}

footer{
  position: relative;
  max-width: 1600px;
  margin: 0px auto;
  width: 100%;
  // bottom: 67px;
  bottom: 110px;
  // height: 100%;
}

aside{
  overflow: hidden;
  z-index: 2;
  position: absolute;
  height: 100%;
}


@media (max-width: 1024px) {
  #root::-webkit-scrollbar{
    display: none;
    width: 0px;
    height: 0px;
  }

  body {
    height: 100%;
    overflow: auto;
    // overflow: hidden;
  }

  main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    // -webkit-box-pack: start;
    //     -ms-flex-pack: start;
    //         justify-content: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    // margin-bottom: 10%;
    // padding-bottom: 20%;
    // height: 100%;
  }

  aside {
    min-height: unset;
    margin-bottom: 40px;
    position: relative;
    z-index: 6;
  }

  .main__wrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

// трансляция работ...
@media (max-width: 768px){
  main {
    // margin: 20px auto 70px auto;
    margin: 20px 0px 70px 0px;
  }
}
@media (max-width: 375px){
  footer {
    // margin: 20px auto 70px auto;
    margin: 0 auto;
  }
}
