@import '../../base.scss';
//autoprefixed--------------------------------------------------------------

.wrap {

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;

    // background-color: cadetblue;
    // margin-bottom: 25vh;

    // &::after{
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     top: 50%;
    //     height: 100%;
    //     width: 100%;
    //     background-image: url('../../assets/img/Prize.png');
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     z-index: -1;

    //     -webkit-transition: all ease 0.3s;

    //     -o-transition: all ease 0.3s;

    //     transition: all ease 0.3s;
    // }
}


.item {
    min-width: 15%;
    width: 89vh;
    max-height: 32%;
    max-width: 54%;
}

.item__video{
    position: relative;
    // padding: 0% 0% 56% 0%; 
    // background-color:sandybrown;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
    width: 100%;
    // padding-bottom: 45%;

    object{
        width: 100%;
        height: 100%;   
        object-position: center;
        object-fit: cover;
        position: absolute;  
        left: 50%;
        top: 0;
        transform: translateX(-50%);  
    }
    iframe{
        width: 100%;
        height: 100%;   
        object-position: center;
        object-fit: cover;
        position: absolute;
        // left: 0;
  
        left: 50%;
        top: 0;
        transform: translateX(-50%);  

    }

}
// .item__video iframe {
//     // display: block;
//     position: absolute;
//     // left: 0;
//     // top: 0;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     // width: 110%;
//     height: 100%;
//     // min-width: 100%;
//     -o-object-fit: cover;
//        object-fit: cover;
//        object-position: center;
//     width: 100vw;

//     // width: 1px;
//     // min-width: 100%;
//     // *width: 100%;
//     // min-height: 100%;

// }



@media (max-width: 1024px) {

    .wrap {
        width: 100%;
        position: relative;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        // padding: 0px 30px;    
    }
    .item{
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        position: relative;
        margin: 0 auto;
        overflow: auto;
        min-width: unset;
        min-height:unset;
        width: 100%;
        height: 100%;
        padding: 0px 30px; 

        // min-width: 15%;
        // width: 89vh;
        max-height: unset;
        max-width: 100%;
    }
    
    .item__video{
        position: relative;
        // padding: 0px 0px 56% 0px;
        overflow: hidden;
        text-align: center;
        object{
            width: 105%;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            display:block;
            display: flex;
            justify-content: center;
            align-items: center;
        }
   
    }

}

@media (max-width: 768px) {
    .wrap {
        // overflow: auto;
        width: 100%;
        position: relative;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        // padding: 0px 20px 0px 20px;  

    }
    
}
