@import '../../base.scss';

.footer__wrap{
    position: absolute;
    // left: 0;
    right: 40px;
    top: 0;
    // padding-left: 40px;
    padding-bottom: 10px;
}
.footer__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0px;
    position: relative;
    div{
        // width: 70px;
        // width: 100px;
        width: 130px;
    }
    img{
        width: 100%;
    }
    div:not(:last-child) {
        margin-right: 10px;
    }
}

.partners {
    // color: $brandColor;
    color: #000;
    font-size: 1rem;
    position: absolute;
    // left: 40px;
    text-transform: uppercase;
    bottom: 100%;
    margin-bottom: 20px;
    right: 0px;
}

@media (max-width: 1024px) {
    .footer__wrap{
        left: 40px;
        // left: 0px;
    }    
    .partners {left: 0px;}
    .footer__links{
        div{
            width: 110px;
        }
    }
}

@media (max-width: 768px) {
    .footer__wrap{
        left: 15px;
        padding: 0 2% 0;
    }
    .partners{
        left: 0px;
        padding: 0 2% 0;
    }
    
}

@media (max-width: 600px) {
    .footer__wrap{
        left: 0px;
        right: 0px;
        
        padding: 0 2% 0;
        width: 100%;
    }

    .footer__links{
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;
    }
    .footer__links{
        div{
            width: 100px;
        }
    }

    
}

@media (max-width: 500px){
    .footer__links::after {
        content: '';
        flex: auto;
      } 
}

@media (max-width: 375px) {
    .footer__wrap{
        // margin: 0 auto;
        // padding: 0 1% 0;
        // right: 0px;
    }
    .footer__links{
        // justify-content: space-between;        

    }
    .footer__wrap{
        // padding: 0 2% 0;
    }   
}

@media (max-width: 350px) {
    .footer__links{
        div{
            width: 80px;
        }
    }
  
}

@media (max-width: 350px) {
    .partners{
        // font-size: 1.6rem;
    }    
}
@media (max-width: 325px) {
    .partners{
        // font-size: 1.4rem;
    }    
}
@media (max-width: 300px) {
    .partners{
        // font-size: 1.3rem;
    }    
}
