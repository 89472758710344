@import '../../base.scss';
//autoprefixed--------------------------------------------------------------


.preloader__wrapper{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    z-index: 100;
    background-color: #fff;
}
.preloader{ 
    height: 75px;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-animation: spin 3s linear infinite;
            animation: spin 3s linear infinite;
}

@-webkit-keyframes spin {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }   
}

@keyframes spin {
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }   
}

//--------------------------------------------------------------------------

// .preloader__wrapper{
//     position: fixed;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     z-index: 100;
//     background-color: #fff;
// }
// .preloader{ 
//     height: 100px;
//     transform: rotate(0deg);
//     animation: spin 3s linear infinite;
// }

// @keyframes spin {
//     0%{
//         transform: rotate(0deg);
//     }
//     100%{
//         transform: rotate(360deg);
//     }   
// }